<template>
  <div :class="[template||'v1']" class="flex" id="sidebar" v-loading="!isIframe && !sidebarData.length">
    <!-- 调试用的，先不删 -->
<!--    <div style="position: fixed;background-color: #f0f0f0;z-index: 1">-->
<!--      {{activeGroup}}#{{activeName}}-->
<!--      <p>{{mySidebarData.length}}</p>-->
<!--    </div>-->

    <!-- 一级分组 -->
    <el-scrollbar v-if="refactor" class="scroll">
      
      <el-menu class="collapse" :unique-opened="true" :collapse="true" :default-active="activeGroup" @select="groupOpen">
        <!--
          @click.native="uniqueOpened?onSidbarItemClick(item):''"
        -->
        <top-sidebar-item v-for="item in topSidebarData" :key="item.node+randomString(6)" :itemData="item"/>
      </el-menu>
    </el-scrollbar>
    <!-- 原来的一级菜单 -->
    <el-scrollbar v-if="mySidebarData.length" class="scroll" :key="childKey">
      
<!--      {{activeName}}-->
<!--      <div style="max-width: 100px">{{activeObj}}</div>-->
      <el-menu :collapse="isCollapse" :default-active="activeName" :unique-opened="uniqueOpened" class="sidebar-menu v2"
        ref="sidebarMenu" v-if="template === 'v2'">
        <SidebarItemV2 :itemData="item" :key="item.node" @click.native="uniqueOpened?onSidbarItemClick(item):''"
          v-for="item in mySidebarData" />
      </el-menu>
      <el-menu :collapse="isCollapse" :default-active="activeName" class="sidebar-menu" v-else
        :unique-opened="uniqueOpened" ref="sidebarMenu">
        <SidebarItem v-for="item in mySidebarData" :key="item.node" :itemData="item"
          @click.native="uniqueOpened?onSidbarItemClick(item):''" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>
<script>
import SidebarItem from "./SidebarItem";
import TopSidebarItem from "@/base/components/Default/TopSidebarItem";
import {randomString} from '../../utils/tool'
import SidebarItemV2 from './SidebarItemV2'

export default {
  provide() {
    return {
      getTemplate: () => this.template
    }
  },
  props: {
    //菜单栏数组
    sidebarData: {
      type: Array,
      required: true,
    },
    navigationData: {
      type: [Array, Object]
    },
    resetSidebar: {
      type: Boolean,
      default: false,
    },
    //是否只展开一个菜单列表
    uniqueOpened: {
      type: Boolean,
      default: false,
    },
    // 使用重构的样式
    refactor: Boolean,
    isIframe: Boolean,
    template: String, // 'v1', 'v2'
  },
  data() {
    return {
      refMenu: null, //el-menu的ref
      uniqueIndex: "", //展开的子菜单name
      activeName: "", //高亮的路由name
      activeObj: null, // 高亮的路由信息
      chindrenData: null, //有子菜单的菜单数组
      /* 新一级菜单 */
      activeGroup: '',
      childKey: randomString(8)
    };
  },
  computed: {
    // 新二级菜单（原来的一级）数据
    mySidebarData() {
      // return this.sidebarData.filter(sd => {
      //   switch(this.activeGroup) {
      //     case 'recent':
      //       return ['cyc_user', 'article', 'activity_menu', 'app_design', 'dataView'].includes(sd.node)
      //     case 'others':
      //     default:
      //       return true
      //   }
      // })
      if (!this.refactor) return this.sidebarData;
      return this.sidebarData.find(sd2 => sd2.node === this.activeGroup)?.children || [];
    },
    /* 新一级菜单数据 */
    topSidebarData() {
      return this.sidebarData.map(s => {
        // 不需要 children 字段
        // const {children, ...needed} = s
        const {...needed} = s // 2024年08月23日15:22:02 需要判断消息数
        return needed
      })
    },
    //是否收缩侧边栏
    isCollapse() {
      return this.$store.getters.isCollapse;
    },
    routeName() {
      return this.$route.name;
    },
    //选中的菜单
    // sidebarActive() {
    // const route = this.$route;
    // let name = route.name;
    // if (route.meta && route.meta.activeMenu) {
    //   name = route.meta.activeMenu;
    // } else {
    //   this.$route.matched.forEach((item) => {
    //     if (item.meta && item.meta.activeMenu) {
    //       name = item.meta.activeMenu;
    //       return true;
    //     }
    //   });
    // }
    // return name;
    // },
  },
  methods: {
    randomString,
    /* 点击一级菜单 */
    groupOpen(e) {
      this.activeName = ''
      this.groupName = ''
      console.log(e)
      console.log(this.$props)
      this.$nextTick(() => {
        setTimeout(() => {
          if (e === this.activeGroup) {
            const menuItem = this.sidebarData.find(sd => sd.node === e)
            if (menuItem.url === '') {
              /* 点击已展开的新一级菜单就收起 */
              this.setActiveRouterName(this.$route.matched)
            }
          } else this.activeGroup = e
          this.$emit('resize')

          this.childKey = randomString(8)
        }, 50)
      })
    },
    //手动关闭已展开子菜单
    // onManualUnique(data) {
    //   //有子菜单就记录name值
    //   if (data && data.children) {
    //     this.uniqueIndex = data.name;
    //     return;
    //   }
    //   //无子菜单进行折叠已展开的菜单
    //   if (this.uniqueIndex) {
    //     if (!this.refMenu) this.refMenu = this.$refs["sidebarMenu"];
    //     this.refMenu.close(this.uniqueIndex);
    //     this.uniqueIndex = "";
    //   } else if (!this.oneInit) {
    //     const childrenMenu = this.sidebarData.filter((menu) => {
    //       return menu.children;
    //     });
    //     if (!this.refMenu) this.refMenu = this.$refs["sidebarMenu"];
    //     childrenMenu.forEach((item) => {
    //       this.refMenu.close(item.name);
    //     });
    //     this.oneInit = true;
    //   }
    // },
    //设置高亮的路由name
    setActiveRouterName(matched = [], again = false) {
      let name = "";
      //最上层路由的activeMenu
      const firstRoute = matched.find((route) => route.meta.activeMenu);
      if (firstRoute) {
        name = firstRoute.meta.activeMenu;
      } else {
        // 没有activeMenu使用最后一个路由【非右侧横向导航】name
        const lastRoutes = matched.filter((route) => route.name);

        /* 找不到当前路由的顶级分类，再处理 */
        if (again) {
          if (lastRoutes.length > 1) {
            const lastOne = lastRoutes[lastRoutes.length - 1]
            if (lastOne.parent?.redirect) {
              /* 读取重定向过来的 父级 name */
              name = lastOne.parent.name
            } else if (lastRoutes.length) name = lastRoutes.pop().name;
          } else if (lastRoutes.length) name = lastRoutes.pop().name;
        } else if (lastRoutes.length) {
          const lastOne = lastRoutes[lastRoutes.length - 1]
          let isInRightNav = false
          Object.keys(this.navigationData || {}).forEach(k => {
            const nav = this.navigationData[k].find(n => n.url === lastOne.name)
            if (nav) isInRightNav = true
            if (nav) name = k
          })
          if (isInRightNav) {
            // 当前高亮的 $route.name 在右侧内嵌横向导航中，不应该设为 activeName
            console.log(name)
          } else name = lastRoutes.pop().name;
        }
      }
      this.activeName = name;
      this.activeObj = this.findNodeConfig(this.sidebarData, name)

      // 设置新的一级菜单高亮
      if (this.refactor) {
        const groupNodeName = this.findNode(this.sidebarData, name)
        if (groupNodeName) {
          this.activeGroup = groupNodeName
          /* 更新侧边栏宽度 */
          this.$emit('resize')
        } else if (!again) {
          this.setActiveRouterName(matched, true)
        } else {
          this.activeGroup = name
          /* 更新侧边栏宽度 */
          this.$emit('resize')
        }
      }
    },
    /* 递归查找高亮的一级node */
    findNode(children, routeName, topNode = '') {
      const child = children.find(ch => ch.url === routeName)
      if (child) {
        return topNode || child.node
      }
      let resultNode
      children.forEach(ch => {
        const node = this.findNode(ch.children || [], routeName, topNode || ch.node)
        if (node) resultNode = node
      })
      return resultNode
    },
    /* 查看高亮节点对应的接口返回的信息 */
    findNodeConfig(children, routeName) {
      const child = children.find(ch => ch.url === routeName)
      if (child) {
        return child
      }
      let result
      children.forEach(ch => {
        const config = this.findNodeConfig(ch.children || [], routeName)
        if (config) result = config
      })
      return result
    },
    // 菜单点击事件
    onSidbarItemClick(menuData) {
      // 无子菜单
      if (!menuData.children) {
        this.onOffChindren();
      }
    },
    //关闭子菜单
    onOffChindren() {
      //获取所有有子菜单数据
      if (!this.chindrenData) {
        this.chindrenData = this.sidebarData.filter((item) => item.children);
      }
      //获取菜单组件实例
      if (!this.$refMenu) this.$refMenu = this.$refs["sidebarMenu"];
      //关闭所有子菜单
      this.chindrenData.forEach((item) => {
        this.$refMenu?.close(item.name);
      });
    },
  },
  watch: {
    resetSidebar(val) {
      if (val) this.onOffChindren();
    },
    //监听路由
    $route(val) {
      this.setActiveRouterName(val.matched);
    },
    'sidebarData.length': {
      handler(val) {
        if (val) this.setActiveRouterName(this.$route.matched);
      },
      immediate: true
    }
  },
  components: {
    SidebarItemV2,
    TopSidebarItem,
    SidebarItem,
  },
};
</script>
<style lang="scss" scoped>
.sidebar-menu:not(.el-menu--collapse) {
  width: $sidebar-w;
  // min-height: 400px;
}
#sidebar {
  width: var(--left-w);
  position: fixed;
  top: $header-h;
  bottom: 0;
  background-color: $bckgroundW;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  z-index: 10;
  @include no-select();
  transition: width .3s;

  .scroll {
    height: 100%;
    overflow: hidden;

    &+.scroll {
      //border-left: 1px solid $border-color;
    }

    ::v-deep.el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  ::v-deep.el-scrollbar__thumb {
    display: none;
  }

  .collapse {
    width: $group-sidebar-w;
  }
}

::v-deep .el-menu.v2 .el-submenu__title:hover,
{
  background-color: unset;
  border-right-color: transparent;
}

/*::v-deep .el-menu .el-submenu__title:hover,*/
::v-deep .el-menu.v2 .el-submenu .el-menu-item:hover,
::v-deep .el-menu.v2 :not(.top-sidebar-item) .el-menu-item:hover,
::v-deep .el-menu.v2 :not(.top-sidebar-item) .el-menu-item.is-active,
/*::v-deep .el-menu.v2 .el-submenu .el-menu-item.leaf:hover,*/

{
  background-color: unset;
  border-right-color: transparent;

  &:before {
    content: "";
    display: block;
    position: absolute;
    /*left: 16px;*/
    left: 0;
    //background-color: rgba($primary, 0.5);

    /*width: 104px;*/
    /*width: 124px;*/
    width: $sidebar-w;
    /*height: 37px;*/
    height: 45px;
    /*border-radius: 4px;*/
    background: #3576ff0d;
    border-right: 3px solid $primary;
  }
}

/*::v-deep .el-menu .el-submenu__title:hover,*/
::v-deep .abc .el-menu.v2 .el-submenu .el-menu-item.leaf:hover,
{
  background-color: unset;
  border-right-color: transparent;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 16px;
    //background-color: rgba($primary, 0.5);

    /*width: 104px;*/
    /*width: 124px;*/
    width: $sidebar-w;
    /*height: 37px;*/
    height: 45px;
    /*border-radius: 4px;*/
    background: #3576ff0d;
    border-right: 3px solid $primary;
  }
}

::v-deep .abc .el-menu.v2 :not(.top-sidebar-item) .el-menu-item.is-active,
{
  background-color: unset;
  border-right-color: transparent;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 16px;
    //background-color: rgba($primary, 0.5);

    /*width: 104px;*/
    width: 124px;
    height: 37px;
    border-radius: 4px;
    background: #3576ff0d;
  }
}
</style>
