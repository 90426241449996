<template>
  <div
    id="admin"
    :style="{
      '--group-w': refactor ? '90px' : '0px',
      '--mini-sidebar-w': '64px',
      '--left-w':
        refactor && simple
          ? '90px'
          : isCollapse
          ? refactor
            ? (`calc(64px + 90px)`||'154px')
            : '64px'
          : refactor
          ? `calc(90px + ${sidebarW}px)`
          : `${sidebarW}px`,
      '--sidebar-w': `${sidebarW}px`,
      '--header-h': '60px',
    }"
  >
    <Header
      :fullscreen-names="fullscreenNames"
      :offSidebarChildren="onOffSidebarChildren"
    />
    <Sidebar
      ref="mySidebar"
      :sidebar-data="sidebarData"
      :navigation-data="navigationData"
      :refactor="refactor"
      uniqueOpened
      :reset-sidebar="resetSidebar"
      :template="sidebarTemplate"
      @resize="onResize"
    />
    <main
      v-show="!sidebarLoading"
      id="main"
      :class="['flex-col']"
      :style="{
        minWidth: $route.meta && $route.meta.fitContent ? 'fit-content' : '',
      }"
    >
      <div
        v-if="!Object.keys(iframeRouteData).includes($route.name)"
        class="navbar"
      >
        <Breadcrumb
          v-if="!Object.keys(navigationData).length || !isInlineNav"
          :root="rootBreadcrumb"
          :template="sidebarTemplate"
        />
        <three-navigation v-else :navigation-data="navigationData" is-new />
      </div>
      <ServerTips />
      <div
        id="main-container"
        class="main-container flex-col"
        :class="{
          involved: involvedNames.includes($route.name),
          noPadding: noPaddingNames.includes($route.name),
        }"
        ref="main-container"
        v-loading="mainLoading"
      >
        <router-view v-if="!refresh" />
        <router-loading v-else />
      </div>
    </main>
    <upgrade-dialog
      :show="upgrade.show"
      :progress="upgrade.progress"
      @confirm="handleFinish"
    >
    </upgrade-dialog>
    <!-- 媒体库弹窗 -->
    <MediaDialog />
    <!-- 裁剪弹窗 -->
    <crop-dialog :is-dialog="true" />
  </div>
</template>
<script>
import Header from '../components/Header'
import ServerTips from '../components/ServerTips'
import Sidebar from '@/base/components/Default/Sidebar'
import Breadcrumb from '@/base/components/Default/Breadcrumb'
import ThreeNavigation from '@/base/components/Default/ThreeNavigation2'
import { getType } from '@/base/utils/tool'
import {
  getUpdateList,
  initFeature,
  initSuccess,
  menuData,
} from '../api/sidebar'
import RouterLoading from '@/base/components/Default/RouterLoading'
import debounce from 'lodash/debounce'
// 队列管理
import ATM from 'x-atm'
import UpgradeDialog from '../components/UpgradeDialog'
import MediaDialog from '@/modules/common/components/MediaDialog.vue'
import { randomString } from '../../../base/utils/tool'
import CropDialog from '../../../base/components/Media/CropDialog'
// 初始化队列管理: 5线程、严格模式、失败可重试0次
const task = new ATM({
  maxParallel: 1,
  strict: false,
  maxRetry: 0,
})
export default {
  provide() {
    return {
      mediaKey: this.mediaKey,
    }
  },
  data() {
    const sidebarTemplate = 'v2' // TODO: 在这里切换菜单样式，目前有v1、v2选项
    return {
      mediaKey: randomString(),
      sidebarTemplate,
      sidebarData: [], //侧边栏数据
      resetSidebar: false, // 是否重置侧边栏状态
      upgrade: {
        show: false,
        progress: 0,
      },
      /* 只有分类 */
      simple: true,
      /* 是否有分类 */
      refactor: true,
      rootBreadcrumb: null,

      // 无背景色
      involvedNames: [
        'AppDesignApp',
        'AppDesignIndex',
        'AppDesignH5',
        'Overview',
        'ConsultStats',
        'ConsultServiceStats',
        'pluginsOverviewIndex',
        'EmployDataStats',
        'AddActivityNew',
      ],
      // 无内边距，main-container自带 20px 不需要的就写里面
      noPaddingNames: ['AddActivityNew'],
      // 无菜单，不需要加载菜单数据
      fullscreenNames: [
        'AppDesignPage',
        'FormPage',
        'BigDataDesign',
        'BigDataOverview',
        'ConsultServiceSurveyForm',
        'ActivityFormDesign',
      ],

      sidebarLoading: true,
    }
  },
  computed: {
    sidebarW() {
      return this.sidebarTemplate === 'v1' ? 180 : 140
    },
    // 是否收缩侧边栏
    isCollapse() {
      return this.$store.getters.isCollapse
    },
    // 是否刷新路由页
    refresh() {
      return this.$store.getters.refresh
    },
    //全局loading
    mainLoading({ $store }) {
      return $store.getters['layout/mainLoading']
    },
    iframeRouteData() {
      return this.$store.state.header.iframeRouteData
    },
    //三级菜单数据
    navigationData() {
      if (!this.sidebarData.length) return {}
      return this.getNavigationData(this.sidebarData)
    },
    // 面包屑将替换为节点菜单展示
    isInlineNav() {
      const { navigationData, $route } = this
      return (
        Object.keys(navigationData)
          .map((key) => navigationData[key].map((k) => k.url))
          .filter(
            (f) =>
              f.includes(($route.meta && $route.meta.alias) || $route.name) &&
              f.length > 1
          ).length > 0
      )
    },
  },
  watch: {
    refresh(val) {
      if (val) {
        this.offRefresh()
      }
    },
    activeTitle(val) {
      this.resetSidebar = val === '个人中心'
      this.$refs['main-container'].scrollTop = 0
    },
  },
  methods: {
    handleFinish() {
      this.upgrade.show = false
      // 刷新当前页面
      // this.$router.go(0);
      this.$router
        .replace({ name: 'Home' })
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          window.location.reload()
        })
    },
    // 获取导航菜单数据
    getMenuData() {
      // if (["AppDesignPage", "FormPage"].includes(this.$route.name)) return;

      // this.sidebarData?.length &&
      if (this.fullscreenNames.includes(this.$route.name)) {
        if (this.sidebarLoading) this.sidebarLoading = false
        return
      }

      this.sidebarLoading = true
      menuData()
        .then((res) => {
          const { data } = res
          this.$store.commit('setSidebarData', data)
          this.sidebarData = data
          // 交给 Home 自己决定跳哪里，初始时路由守卫会处理跳到 Home
          // if (this.$route.name === "Home") {
          //   this.$router.push({ name: data[0].url || data[0].children[0].url });
          // }
        })
        .catch((err) => {
          if (err.code === 11000) {
            // 1. 获取需要升级的功能列表
            getUpdateList()
              .then((res) => {
                this.upgrade.show = true
                setTimeout(() => {
                  // 2. 逐个初始化功能
                  this.initFeatures(res.data)
                }, 1000)
              })
              .catch((err) => {
                console.log('init features fail: ', err)
                if (err && err.code === 0) {
                  // 3. 初始化完成后统一调用，不管成功还是失败
                  initSuccess()
                    .then((res) => {
                      // this.$message.success(res.msg)
                    })
                    .catch((err) => {})
                }
              })
              .finally(() => {})
          }
        })
        .finally(() => {
          this.sidebarLoading = false
        })
    },
    initFeatures(list) {
      list.forEach((li) => {
        let asyncTask = () => {
          return initFeature(li.feature)
        }
        asyncTask.resolve = () => {
          // 已经执行了多少个任务
          const finished = task.query().finished
          // 一共有多少个
          const count = task.query().count
          // console.log(finished, count)
          if (finished === count) {
            this.upgrade.progress = 100
            // 3. 初始化完成后统一调用，不管成功还是失败
            initSuccess()
              .then((res) => {
                // this.$message.success(res.msg)
              })
              .catch((err) => {})
          } else {
            this.upgrade.progress = Math.floor((finished * 100) / count)
          }
        }
        task.push(asyncTask)
      })
      task.start()
    },
    //获取三级菜单数据（2023年02月14日14:17:50变成四级了）
    getNavigationData(data = []) {
      let navObj = {}
      data.filter((one) => {
        //有二级菜单，并且二级菜单里存在children：array
        if (
          getType(one.children) === 'array' &&
          one.children.find((two) => getType(two.children) === 'array')
        ) {
          if (one.url) navObj[one.url]= one.children
          // if (one.url && navObj.one) navObj.one.url = one.children

          //遍历这个二级菜单拿到children
          one.children.forEach((twoItem) => {
            if (getType(twoItem.children) === 'array') {
              if (twoItem.url) navObj[twoItem.url] = twoItem.children
              twoItem.children.forEach((threeItem) => {
                if (getType(threeItem.children) === 'array') {
                  // if (this.refactor) {
                  //   threeItem.children.forEach(fourItem => {
                  //     // console.log('~~~~~~~~~~~~~~~~~')
                  //     // console.log(fourItem.node, fourItem.url, fourItem.name, 'fffffffffffffff')
                  //     // console.log(threeItem.node, threeItem.url || '--', threeItem.name, 'ggggggggggggggg')
                  //     // console.log('==================')
                  //     // if (getType(fourItem.children) === "array" && fourItem.url) {
                  //     //   navObj[fourItem.url] = fourItem.children;
                  //     // } else
                  //       if (threeItem.url) {
                  //       navObj[threeItem.url] = threeItem.children;
                  //     }
                  //   })
                  // } else {
                  /* 有url的节点，其下的孩子节点作为页面导航出现，不在左侧导航出现 */
                  if (threeItem.url) navObj[threeItem.url] = threeItem.children
                  // }
                }
              })
            }
          })
        }
      })
      return navObj
    },
    //结束刷新
    offRefresh() {
      this.$store.commit('setRefresh', false)
    },
    //关闭侧边栏子菜单
    onOffSidebarChildren() {
      this.resetSidebar = true
      if (this.$timer) clearTimeout(this.$timer)
      this.$timer = setTimeout(() => {
        this.resetSidebar = false
      }, 300)
    },
    onResize() {
      this.simple = !this.$refs.mySidebar?.mySidebarData.length

      this.$nextTick(() => {
        this.rootBreadcrumb = this.$refs.mySidebar?.activeObj
      })
    },
  },
  created() {
    const sidebarData = this.$store.getters.sidebarData || []
    if (!sidebarData.length) {
      let localData = sessionStorage.getItem('sidebarData')
        ? JSON.parse(sessionStorage.getItem('sidebarData'))
        : []
      if (localData?.length) {
        this.$store.commit('setSidebarData', localData)
        this.sidebarData = localData
        this.sidebarLoading = false
      } else {
        // 获取导航菜单数据
        this.getMenuData()
      }
    } else {
      this.sidebarData = sidebarData
      this.sidebarLoading = false
    }
    // 防抖动
    this.offRefresh = debounce(this.offRefresh, 300)
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize()
    })

    // 监听菜单消息红点变化
    this.$bus.on('admin_msg_update', (data) => {
      const {amount, type, url} = data;
      const messagesList = this.$store.state.socket.messagesData
      if (!messagesList) return
      let messageData = messagesList[url]
      if (messageData) {
        if (type === 'inc') {
          this.$store.commit('socket/updateMessageData', {
            key: url,
            val: messageData + amount
          })
          // const notification = this.$notify({
          //   title: '新消息提醒',
          //   message: '您有'+ amount +'条新消息待处理~点击去处理',
          //   position: 'bottom-right',
          //   duration: 5000,
          //   onClick: () => {
          //     let routerData = this.$router.resolve({
          //       name: url
          //     })
          //     window.open(routerData.href, '_blank')
          //     if (notification) notification.close()
          //   }
          // });
        } else if (type === 'dec') {
          if (messageData - amount >= 0) {
            this.$store.commit('socket/updateMessageData', {
              key: url,
              val: messageData - amount
            })
          } else {
            this.$store.commit('socket/updateMessageData', {
              key: url,
              val: 0
            })
          }
        }

        if (['cyc_company_assoc', 'cyc_company_new', 'cyc_company_ident'].includes(url)) {
          this.$bus.emit('admin_msg_update', ({
            amount,
            type,
            url: 'CycCompanyCheckList'
          }))
        }

        if (['unitUserCheckingCount', 'userCheckingCount'].includes(url)) {
          this.$bus.emit('admin_msg_update', ({
            amount,
            type,
            url: 'CycUserList'
          }))
        }
      }
    })
  },
  beforeDestroy() {
    // 开发时记得注释掉不然会频繁触发
    // this.$store.commit('socket/closeSocket')
  },
  components: {
    CropDialog,
    UpgradeDialog,
    Header,
    ServerTips,
    Sidebar,
    Breadcrumb,
    RouterLoading,
    ThreeNavigation,
    MediaDialog,
  },
}
</script>
<style lang="scss" scoped>
#main {
  position: relative;
  margin-top: $header-h;
  min-height: calc(100vh - #{$header-h});
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease-in-out;
  background-color: $bg-color;
  margin-left: var(--left-w);

  .navbar {
    position: sticky;
    top: 60px;
    width: 100%;
    flex-shrink: 0;
    // height: 50px;
    padding: 10px 20px;
    background-color: $bckgroundW;
    z-index: 3;
  }

  .main-container {
    flex-grow: 1;
    margin: $space;
    padding: $space;
    @include card();

    &.involved {
      background-color: unset;
    }
    &.noPadding {
      padding: 0;
    }
  }
}
</style>
