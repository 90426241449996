<template>
  <div :class="[template]" class="top-sidebar-item nav-content" v-if="itemData">
    <template v-if="itemData.url" class="nav-item">
      <!-- 链接跳转 -->
      <el-menu-item
        :index="itemData.url"
        @click="openLink(itemData)"
        class="nav-item link"
        v-if="/^http/.test(itemData.url) || itemData.is_open_new_window"
      >
        <div class="menu-item-content flex-col flex-center">
          <img
            :src="itemData.icon"
            class="icon no-active"
            v-if="itemData.icon"
          />
          <img
            :src="itemData.icon_selected"
            class="icon active"
            v-if="itemData.icon_selected"
          />
          <span class="name">{{ itemData.name }}</span>
        </div>
      </el-menu-item>
      <!-- 无子菜单 -->
      <el-menu-item
        :index="itemData.node"
        :route="{ name: itemData.url }"
        @click="onRefresh(itemData.url)"
        class="nav-item one-level"
        v-else
      >
        <div class="menu-item-content flex-col flex-center">
          <el-badge
            :hidden="!checkItemChildrenMessage(itemData)"
            :is-dot="true"
            class=""
            style="overflow: visible"
          >
            <img
              :src="itemData.icon"
              class="icon no-active"
              v-if="itemData.icon"
            />
            <img
              :src="itemData.icon_selected"
              class="icon active"
              v-if="itemData.icon_selected"
            />
          </el-badge>
          <span class="name">{{ itemData.name }}</span>
        </div>
      </el-menu-item>
    </template>
    <el-menu-item
      :index="itemData.node"
      :key="itemData.name"
      :route="{ name: itemData.url || itemData.node }"
      @click="onRefresh(itemData.url, itemData.name)"
      class="nav-item menu-item sub"
      v-else-if="true || !itemData.children || !itemData.children.length"
    >
      <div class="menu-item-content flex-col flex-center">
        <el-badge
          :hidden="!checkItemChildrenMessage(itemData)"
          :is-dot="true"
          :value="checkItemChildrenMessage(itemData) || ''"
          style="overflow: visible"
        >
          <img
            :src="itemData.icon"
            class="icon no-active"
            v-if="itemData.icon"
          />
          <img
            :src="itemData.icon_selected"
            class="icon active"
            v-if="itemData.icon_selected"
          />
        </el-badge>
        <span class="name" slot="title">{{ itemData.name }}</span>
      </div>
    </el-menu-item>
    <!-- 有子菜单 -->
    <el-submenu :index="itemData.node" v-else-if="false">
      <template slot="title">
        <div class="nav-item">
          <img
            :src="itemData.icon"
            class="icon no-active"
            v-if="itemData.icon"
          />
          <img
            :src="itemData.icon_selected"
            class="icon active"
            v-if="itemData.icon_selected"
          />
          <span class="name" slot="title">{{ itemData.name }}</span>
        </div>
      </template>
      <template v-for="two in itemData.children">
        <!--    v-if="!two.children || !two.children.length"     -->
        <el-menu-item
          :index="two.url"
          :key="two.name"
          :route="{ name: two.url }"
          @click="onRefresh(two.url, two.name)"
        >
          <i></i>{{ two.name }}
        </el-menu-item>
        <!--        <el-submenu v-else :index="two.url" :route="{ name: two.url }" class="flex-col nav-item"-->
        <!--                    @click="onRefresh(two.url)">-->
        <!--          <template slot="title"><i style="margin-right: 6px;"></i>{{ two.name }}foo</template>-->
        <!--          <el-menu-item v-for="t in two.children" :key="t.node" :index="t.url"-->
        <!--                        :route="{ name: t.url }" @click="onRefresh(t.url)">{{t.name}}</el-menu-item>-->
        <!--        </el-submenu>-->
      </template>
    </el-submenu>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'top-sidebar-item',
  inject: {
    getTemplate: {
      value: 'getTemplate',
      default: () => 'v1',
    },
  },
  props: {
    //每个导航数据
    itemData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('socket', ['messagesData']),
    routeName() {
      return this.$route.name
    },
    tenantVersion() {
      return this.$store.getters.tenant?.version
    },
    template() {
      return this.getTemplate()
    },
  },
  methods: {
    checkItemChildrenMessage(item) {
      if (!this.messagesData) return false

      if (item.url && this.messagesData[item.url] > 0)
        return this.messagesData[item.url]

      const children = item.children || []
      let sum = 0
      for (let i = 0; i < children.length; i++) {
        const current = children[i]
        const _bol = this.checkItemChildrenMessage(current)
        if (_bol) {
          sum += _bol
        }
      }
      return sum
    },
    //外链跳转
    openLink(data) {
      const isOuterLink = /^http/.test(data.url)
      let link
      if (isOuterLink) {
        link = data.url
      } else {
        let routerData = this.$router.resolve({
          name: data.url,
        })
        link = routerData.href
      }
      window.open(link, data.is_open_new_window ? '_blank' : '_self')
      return false
    },
    //菜单事件
    onRefresh(name, title) {
      console.log(name, title)
      // 刷新
      let queryObj = {
        // query: { title }
      }
      const defaultTab = {
        CycUserList: this.tenantVersion === 'school' ? 'normal' : 'formal',
        ApprovalList: 'handling',
      }
      let paramsObj = {}
      if (Object.keys(defaultTab).includes(name)) {
        paramsObj.params = { tab: defaultTab[name] }
      }

      if (name === this.routeName) {
        this.$router
          .replace({ name, ...paramsObj, ...queryObj })
          .catch(() => {})
        this.$store.commit('setRefresh', true)
      } else {
        // 点击了插件模块 Plugin
        if (name === 'Plugin') {
          // 点击插件模块直接跳转预览
          this.$router.push({ name: 'pluginsOverview' }).catch(() => {})
        } else {
          this.$router.push({ name, ...paramsObj, ...queryObj }).catch(() => {})
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.top-sidebar-item {
  .el-menu-item {
    &:hover {
      color: $primary;
    }

    .menu-item-content {
      width: 100%;
    }
  }
}

.top-sidebar-item.v2 {
  color: #1a1a1a !important;

  .el-menu-item {
    height: 72px;
    border-right: 3px solid transparent;

    &:hover,
    &.is-active {
      color: $primary !important;
      border-color: $primary;
      background-color: #f0f5ff;
    }

    &.is-active {
      .menu-item-content {
        :not(i) {
          font-weight: 700;
        }
      }
    }

    .menu-item-content {
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-top: 0;

      .name {
        font-weight: 500;
        margin-top: 4px;
      }
    }
  }

  .nav-item .icon {
    width: 24px;
    height: 24px;
  }
}

.flex-col {
  ::v-deep.el-submenu__title {
    width: 100%;
  }
}

.icon {
  /* Keyword values */
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;

  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;

  width: 20px;
  height: 20px;
}

.nav-item.sub {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.top-sidebar-item.v1 {
  .menu-item-content {
    :not(i) {
      @include nowrap();
    }
  }

  .el-submenu ::v-deep .el-submenu__title {
    height: 60px;
  }

  .el-menu-item,
  .el-submenu {
    height: 60px;

    [class^='el-icon-'] {
      margin-right: 0;
    }

    .menu-item-content {
      height: 100%;
      line-height: 1.5;

      .name {
        font-size: 12px;
        margin-top: $space * 0.25;
      }
    }
  }
}
</style>
